import { fg } from '@atlaskit/platform-feature-flags';

import { sha256Hash } from '../utils';

import type { commonAttributesType, NLPSearchResultSource } from './types';

const sanitizeSources = (sources: NLPSearchResultSource[]): { id: string; type: string }[] =>
	sources.map((source) => ({
		id: source.ari ?? source.id,
		type: source.type,
	}));

const has3PSources = (sources: NLPSearchResultSource[]): { id: string; type: string }[] =>
	sources.filter((source) => source.ari?.includes('third-party') || source.ari?.includes('graph'));

/**
 * Any keys that exactly match an entry in this Set will _NOT_ be sanitized. So be sure that anything
 * you add here will not contain PII or UGC
 */
const ALLOWED_EXTRA_ATTRIBUTES_KEYS = new Set([
	'contentId',
	'readingAidsSessionId',
	'fieldType',
	'multipleUserResultsShown',
	'numAddtlWhoMatches',
	'isCuratedDefinition',
	'isPrecomputedDefinition',
	'editDistance',
	'bookmarkId',
	'bookmarkState',
	'dwellTime',

	// from extraAPIAnalyticsAttributes
	'message_id',
	'conversational_channel_id',
	'experience_id',
	'user_id',

	// from extraAnalyticsAttributes
	'experiments',
	'featureGates',
	'isFullPageSearch',
	'has3pProducts',
	'is3pSearch',
	'mode',
	'triggeredBy',
]);

type ExtraAttributes = {
	[key: string]: any;
};
export const sanitizeExtraAttributes = (extraAttributes: ExtraAttributes) => {
	if (!fg('kd_fe_sanitize_analytics')) {
		return extraAttributes;
	}

	const sanitizedAttributes: ExtraAttributes = {};
	// Sanitize everything except COMMON_ALLOWED_KEYS
	for (const key in extraAttributes) {
		if (ALLOWED_EXTRA_ATTRIBUTES_KEYS.has(key)) {
			sanitizedAttributes[key] = extraAttributes[key];
		} else {
			// replacing with text because the value has type `any`
			sanitizedAttributes[key] = '[sanitized]';
		}
	}

	return sanitizedAttributes;
};

// New instrumentation attributes must be added to this function to be included in all the events
export const filterCommonAttributes = (props: commonAttributesType) => {
	const {
		appliedFilters,
		answerFormat,
		answerLength,
		answerWordCount,
		answerCardType,
		followUps,
		sources,
		extraAttributes,
		searchSessionId,
		queryHash,
		baseQueryHash,
		query,
		answerString,
		apiSource,
		brand,
		sessionId,
	} = props;

	return {
		searchSessionId,
		queryHash,
		baseQueryHash,
		wordCount: (query.match(/\w+/g) || []).length,
		queryLength: query.length,
		answerHash: sha256Hash(answerString),
		appliedFilters,
		answerFormat,
		answerLength,
		answerWordCount,
		answerCardType,
		followUpsCount: followUps.length,
		followUpsLength: followUps.map((followUp) => followUp.length),
		has3PSources: has3PSources(sources).length ? true : false,
		sources: sanitizeSources(sources),
		apiSource,
		brand,
		sessionId: sessionId,
		...sanitizeExtraAttributes(extraAttributes),
	};
};
