import React, {
	Fragment,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
	useEffect,
} from 'react';
import { Subscribe } from 'unstated';
import { useIntl } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import type { EventHandlers } from '@atlaskit/editor-common/ui';
import type { RendererAppearance } from '@atlaskit/renderer';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import { useAnalyticsEvents, AnalyticsListener } from '@atlaskit/analytics-next';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { DocNode } from '@atlaskit/adf-schema';
import UFOSegment from '@atlaskit/react-ufo/segment';

import { DataSourceProvider as ReferentialityDataSourceProvider } from '@atlassian/editor-referentiality';
import { useAutohighlightSupplier, AutoHighlightsProduct } from '@atlassian/search-ai';

import { fg } from '@confluence/feature-gating';
import {
	ADFRenderer,
	createLinkEventHandler,
	createSmartCardEventHandler,
} from '@confluence/adf-renderer';
import { useRendererAnnotationProviderOptions } from '@confluence/annotation-provider';
import {
	useDocumentUpdateStatus,
	useDocumentUpdateStatusDispatch,
} from '@confluence/annotation-provider-store';
import { getConnectDataListener } from '@confluence/app-referentiality/entry-points/connectDataListener';
import { BannerStateContainer } from '@confluence/banners';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import {
	ContentBlogUnifiedQuery,
	ContentUnifiedQuery,
	type ContentUnifiedQueryMacroRenderedOutputType,
} from '@confluence/content-unified-query';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import {
	AI_DEFINITIONS_AUTOHIGHLIGHT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { InlineCommentNudge } from '@confluence/experiment-inline-comment-nudge';
import { InlineCommentLinkHandler } from '@confluence/inline-comments-link-handler';
import { MediaAnalytics } from '@confluence/media-analytics';
import { useRouteActions, useMatchRoute } from '@confluence/route-manager/entry-points/RouteState';
import { useSessionData } from '@confluence/session-data';
import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';
import { CONTENT_RENDERER_METRIC, RendererTTIMarker } from '@confluence/view-content-perf-metrics';
import { ExtrensionIndexProvider } from '@confluence/extensions-performance';
import { getAutoConvertionInfo } from '@confluence/editor-conversion';
import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import { useInlineComments } from '@confluence/inline-comments-hooks';
import { useAddCommentPermissionCheck } from '@confluence/comments-hooks';
import { RendererExtensionContext } from '@confluence/content-renderer-extension-context';
import {
	getSsrExtensionHandlers,
	removeDuplicateResourcesForMacros,
} from '@confluence/content-renderer-legacy-macros';
import { ReattachCommentPanelLoader } from '@confluence/inline-comments-reattach';
import { useReattachComment, useUpdateDocument } from '@confluence/comment-context';
import { MentionScrollComponent } from '@confluence/mention';
import {
	RendererTextHighlighter,
	useHighlightAcronyms,
} from '@confluence/contextual-reading-aids/entry-points/RendererTextHighlighter';
import { InvisibleLoadingContainer as ReadingAidsInvisibleLoadingPortal } from '@confluence/contextual-reading-aids/entry-points/ReadingAidsLoadingContainer';
import { getHighlightCategories } from '@confluence/contextual-reading-aids/entry-points/reading-aids-utils';
import { MultiMacroQueryContainer } from '@confluence/fabric-extension-queries';
import { useCommentsData, useUpdateAnnotations } from '@confluence/comments-data';
import { useIsAIEnabledForContent } from '@confluence/ai-common/entry-points/useIsAIEnabledForContent';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

import { handleAnalyticsEvents } from '../handleAnalyticsEvents';
import { includeSuperbatchForFabricMacro } from '../PageContentRenderer';
import { WithAnnotationsWrapper } from '../WithAnnotationsWrapper';
import { useReadAndWriteContentQueryDataCache } from '../hooks/useReadAndWriteContentQueryDataCache';
import type { ContentBodyType, ContentNodeType } from '../__types__';
import { useExtensionViewportSizes } from '../hooks/useExtensionViewportSizes';

import { StyledRenderer } from './StyledRenderer';

type PageContentRendererFabricProps = {
	content: ContentNodeType;
	contentAppearance: RendererAppearance;
	contentBody: ContentBodyType;
	contentId: string;
	eventHandlerOverrides?: Partial<EventHandlers>;
	hasInlineComments?: boolean;
	isPreviewMode?: boolean;
	isPresentational?: boolean;
	isSpaceOverview?: boolean;
	isTemplateEditorRenderer?: boolean;
	markerRefs: string[];
	queryHash?: string;
	renderPerformanceMetrics: (props) => (...args: any) => JSX.Element;
	revision: string;
	rootEl: HTMLDivElement | null;
	setRootEl: (el: HTMLDivElement | null) => void;
	spaceKey?: string | null;
	spaceId?: string;
	isArchived?: boolean;
	contentStatusError?: ApolloError;
	isContentHeaderFixedAtTop?: boolean;
	customPerfData?: Record<string, string | boolean | object>;
};

const fabricErrorBoundaryAttributes = {
	fabric: true,
};

export const useADFDocument = (adf: string, isFabric: boolean): [DocNode] => {
	const { documentUpdated } = useDocumentUpdateStatus();
	const docParsed = useMemo(() => {
		let documentParsed: DocNode = {
			version: 1,
			type: 'doc',
			content: [],
		};

		if (adf && isFabric) {
			try {
				documentParsed = JSON.parse(adf);
			} catch (e) {
				throw Error('Failed to parse ADF');
			}
		}
		return documentParsed;
	}, [adf, isFabric]);

	const [document, setDocument] = useState(docParsed);

	useEffect(() => {
		setDocument(docParsed);
	}, [docParsed, documentUpdated]);
	return [document];
};

export const PageContentRendererFabricInternal = ({
	content,
	contentId,
	contentBody,
	rootEl,
	setRootEl,
	hasInlineComments,
	isSpaceOverview,
	eventHandlerOverrides,
	isTemplateEditorRenderer,
	spaceKey,
	spaceId,
	isPreviewMode,
	renderPerformanceMetrics,
	revision,
	contentAppearance,
	markerRefs,
	queryHash,
	isArchived,
	contentStatusError,
	isContentHeaderFixedAtTop,
	customPerfData,
	isPresentational,
	...rest
}: PageContentRendererFabricProps) => {
	const { featureFlags, environment, cloudId, userId, locale, activationId } = useSessionData();
	const { setDocumentUpdated } = useDocumentUpdateStatusDispatch();
	const [, { setUpdateDocumentFn }] = useUpdateDocument();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [state] = useContentState();
	const localRef = useRef<HTMLDivElement>(null);
	const [manifestReadyState, setManifestReadyState] = useState(false);
	const [renderComplete, setRenderComplete] = useState(false);
	const { getExtensionHandlers, populateExtensionProviders } = useContext(RendererExtensionContext);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { push } = useRouteActions();
	const matchRoute = useMatchRoute();
	const { isAIEnabledForContent, isRovoEnabledForContent } = useIsAIEnabledForContent({
		contentId,
	});
	const contentType = content?.type;
	const lastModifiedDate = content?.metadata?.lastModifiedDate;
	const adf = contentBody?.value || '';
	const isFabric = contentBody?.representation === 'atlas_doc_format';
	const { canAddComments } = useAddCommentPermissionCheck(contentId);
	const displayInlineCommentNudger = canAddComments && hasInlineComments && !isSpaceOverview;
	const isReadingAidsAutoHighlightEnabled =
		(isAIEnabledForContent || isRovoEnabledForContent) &&
		locale.startsWith('en') &&
		!JSON.parse(
			localStorage.getItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_READING_AIDS_AUTO_HIGHLIGHT) || '""',
		);

	const { acronymRegex } = useHighlightAcronyms({
		skipNetworkCall:
			fg('kd_definitions_autohighlight_supplier') || !isReadingAidsAutoHighlightEnabled,
	});

	const {
		keyPhrases,
		autoHighlightRegex,
		loadAutohighlights,
		error: autohighlightsError,
	} = useAutohighlightSupplier({
		product: AutoHighlightsProduct.CONFLUENCE,
		contentId,
		cloudId,
		activationId,
		contentType: contentType || 'page',
	});
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	const isSSR = Boolean(process.env.REACT_SSR);
	const [hideHeadingIds, setHideHeadingIds] = useState(true);
	const extensionViewportSizes = useExtensionViewportSizes();
	const renderServerPlaceholder = useRenderServerPlaceholder();

	useEffect(() => {
		const t = setTimeout(() => {
			setHideHeadingIds(false);
		}, 0);

		return () => clearTimeout(t);
	}, []);

	useEffect(() => {
		if (isReadingAidsAutoHighlightEnabled && fg('kd_definitions_autohighlight_supplier')) {
			experienceTracker.start({
				name: AI_DEFINITIONS_AUTOHIGHLIGHT_EXPERIENCE,
				attributes: {
					contentId,
				},
			});
			try {
				void loadAutohighlights({ contentId });
				experienceTracker.succeed({
					name: AI_DEFINITIONS_AUTOHIGHLIGHT_EXPERIENCE,
					attributes: {
						contentId,
					},
				});
			} catch (error) {
				experienceTracker.fail({
					name: AI_DEFINITIONS_AUTOHIGHLIGHT_EXPERIENCE,
					attributes: {
						contentId,
					},
					error,
				});
			}

			if (!fg('kd_definitions_click_and_show_event_product_parity')) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'shown',
						actionSubject: 'readingAidsAcronymHighlights',
						source: 'viewPageScreen',
						attributes: {
							contentId,
							numHighlights: keyPhrases.length,
							categories: getHighlightCategories(keyPhrases),
							beAcronymsEnabled: true,
						},
					},
				}).fire();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId]);

	const [, { updateUnresolvedInlineComment }] = useInlineComments();
	const [, { setOrderedActiveAnnotationIdList }] = useCommentsData();
	const { isInReattachMode } = useReattachComment();

	const eventHandlers = useMemo(
		() => ({
			...createSmartCardEventHandler({ push, matchRoute }),
			...createLinkEventHandler({ push, matchRoute }),
			...eventHandlerOverrides,
		}),
		[push, matchRoute, eventHandlerOverrides],
	);

	const macroRenderedOutput = useMemo(
		() =>
			content && 'macroRenderedOutput' in content
				? removeDuplicateResourcesForMacros(
						content['macroRenderedOutput'] as ContentUnifiedQueryMacroRenderedOutputType[],
					)
				: null,
		[content],
	);

	const isAutoConverted = useMemo(() => {
		const { editorProperty } = getAutoConvertionInfo(content);
		return !editorProperty;
	}, [content]);

	/**
	 * Inline Edit section starts
	 */
	const [adfDocument] = useADFDocument(adf, isFabric);

	let chooseContentUnifiedQuery = ContentUnifiedQuery;
	if (contentType === 'blogpost') {
		chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
	}

	const readAndWriteQueryCache = useReadAndWriteContentQueryDataCache({
		query: chooseContentUnifiedQuery,
		contentId,
		spaceKey,
	});

	/**
	 * Inline Edit section ends
	 */

	// If legacy fabric macros exist, superbatch css and js needs to be added
	// only once before any macros are rendered.
	const includeSuperbatch = useCallback(() => {
		includeSuperbatchForFabricMacro(contentBody, macroRenderedOutput);
	}, [contentBody, macroRenderedOutput]);

	// Extension handlers during SSR should not be used to populate extension providers
	// Otherwise extensions manifest would reference the wrong handlers if it needs to be overrided
	// during runtime
	const extensionHandlersWithSSR: any = useMemo(
		() =>
			getSsrExtensionHandlers({
				adf: contentBody?.value,
				contentId: contentId || '',
				spaceKey,
				cloudId,
				userId,
				macroRenderedOutput,
				includeSuperbatch,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[includeSuperbatch],
	);

	let handlers: ExtensionHandlers | undefined = undefined;
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		// Return extension handlers for supported content only
		handlers = extensionHandlersWithSSR;
	} else {
		// POST/NON SSR, macros V2 is on, and manifest is ready, extension handlers is undefined to use extension manifest
		// Don't use client side macro renderer until manifestReadyState because it takes additional cycle to render
		// The loading state will destroy the SSR rendered content
		handlers =
			manifestReadyState || !window.__SSR_RENDERED__ ? undefined : extensionHandlersWithSSR;
	}

	// e.g. this runs when a new top level comment is made on the page
	const updateDocument = useCallback(
		(newAdfDoc: JSONDocNode) => {
			readAndWriteQueryCache(newAdfDoc as DocNode);
			setDocumentUpdated(true);
			// Give document time to update
			// TODO: turn this into an async function
			setTimeout(() => {
				setDocumentUpdated(false);
			}, 1500);
		},
		[readAndWriteQueryCache, setDocumentUpdated],
	);

	useEffect(() => {
		// Set the updateDocument function whenever the renderer mounts with a new contentId
		setUpdateDocumentFn(updateDocument);
	}, [contentId, setUpdateDocumentFn, updateDocument]);

	useEffect(() => {
		if (autohighlightsError) {
			experienceTracker.fail({
				name: AI_DEFINITIONS_AUTOHIGHLIGHT_EXPERIENCE,
				attributes: {
					contentId,
				},
				error: autohighlightsError,
			});
		}
	}, [autohighlightsError, contentId, experienceTracker]);

	const updateUnresolvedInlineCommentFn = useUpdateAnnotations([
		updateUnresolvedInlineComment,
		setOrderedActiveAnnotationIdList,
	]);

	const rendererAnnotationProviderOptions = useRendererAnnotationProviderOptions({
		pageId: contentId,
		validAnnotationsCallback: fg('confluence-frontend-comments-panel')
			? updateUnresolvedInlineCommentFn
			: updateUnresolvedInlineComment,
		triggerAnalyticsEvent: createAnalyticsEvent,
		updateDocument,
		isArchived,
	});

	const annotationProvider = hasInlineComments ? rendererAnnotationProviderOptions : undefined;

	const referentialityDataSourceProvider = useMemo(() => {
		const refDataProvider = new ReferentialityDataSourceProvider();

		getConnectDataListener().init({
			emit: (localId, reference) => refDataProvider.createOrUpdate(localId, reference),
		});

		return refDataProvider;
	}, []);

	const extensionHandlers: any = useMemo(() => {
		// Future handlers here, these are the extension handlers that gets populated as extension providers
		return getExtensionHandlers
			? getExtensionHandlers(
					{
						adf,
						contentId,
						spaceKey,
						macroRenderedOutput,
						showTemplateVariablesInPreview: isTemplateEditorRenderer,
						shouldIncludeMacroWithReferencesHandlers: true,
						referentialityDataSourceProvider,
						contentType,
						isPreviewMode,
						annotationProvider,
					},
					createAnalyticsEvent,
				)
			: {};
	}, [
		getExtensionHandlers,
		contentId,
		spaceKey,
		adf,
		macroRenderedOutput,
		contentType,
		isPreviewMode,
		createAnalyticsEvent,
		isTemplateEditorRenderer,
		annotationProvider,
		referentialityDataSourceProvider,
	]);

	const dataProviders = useMemo(() => {
		const providerFactory = ProviderFactory.create({});
		const intlStub = {
			formatMessage: (message) => message?.defaultMessage,
			locale,
		};

		if (populateExtensionProviders) {
			populateExtensionProviders(providerFactory, {
				intl: intlStub,
				containerId: spaceKey,
				accountId: userId,
				cloudId,
				spaceKey,
				spaceId,
				contentId,
				contentType,
				environment,
				featureFlags,
				extensionHandlers,
				setManifestReadyState,
				createAnalyticsEvent,
				activationId,
			});
		}

		return providerFactory;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId, contentType, extensionHandlers, populateExtensionProviders, createAnalyticsEvent]);

	useEffect(() => {
		setRenderComplete(false);
	}, [contentId]);

	const handleOnComplete = useCallback((stats) => {
		window.__RENDERER_NODES_COUNT__ = stats.nodesCount;
		setRenderComplete(true);
	}, []);

	const memoRenderPerformanceMetrics = useMemo(() => {
		return renderPerformanceMetrics({
			contentId,
			isFabric: true,
			appearance: contentAppearance,
			revision,
			isRenderTTIMark: true,
			isAutoConverted,
			lastModifiedDate,
			customPerfData,
		});
	}, [
		contentId,
		contentAppearance,
		revision,
		isAutoConverted,
		lastModifiedDate,
		customPerfData,
		renderPerformanceMetrics,
	]);
	const memoTextHighlighter = useMemo(() => {
		return isReadingAidsAutoHighlightEnabled
			? {
					pattern: fg('kd_definitions_autohighlight_supplier') ? autoHighlightRegex : acronymRegex,
					component: (props) => <RendererTextHighlighter {...props} keyPhrases={keyPhrases} />,
				}
			: undefined;
	}, [isReadingAidsAutoHighlightEnabled, autoHighlightRegex, acronymRegex, keyPhrases]);

	return (
		<ErrorBoundary attribution={Attribution.BACKBONE} attributes={fabricErrorBoundaryAttributes}>
			{contentStatusError && <ErrorDisplay error={contentStatusError} />}
			<Subscribe to={[BannerStateContainer]}>
				{(bannerState: BannerStateContainer) => {
					const bannerHeight = bannerState.getTotalHeight();
					return (
						<div>
							<div id="selection-portal" />
							<PageSegmentLoadStart key={contentId} metric={CONTENT_RENDERER_METRIC} />
							<MediaAnalytics contentId={contentId}>
								<RendererTTIMarker
									revision={revision}
									renderWhenReady={memoRenderPerformanceMetrics}
								>
									{/**
									 * WARN: id and className on a wrapping div are required for some legacy macros to be properly
									 * attached to the elements in the page content
									 */}
									<StyledRenderer
										data-testid="pageContentRendererTestId"
										id="main-content"
										ref={setRootEl}
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className="wiki-content"
										data-test-appearance={contentAppearance}
										isFabric
										bannerHeight={bannerHeight || 0}
										markerRefs={markerRefs}
										intl={intl}
									>
										<ExtrensionIndexProvider document={adfDocument}>
											<AnalyticsListener
												onEvent={handleAnalyticsEvents(contentId)}
												channel="editor"
											>
												<MultiMacroQueryContainer
													contentId={contentId}
													revision={revision}
													featureFlags={featureFlags}
													contentRendererMode="renderer"
													useServerRenderedData={renderServerPlaceholder}
												>
													{/**
													 * StyledRenderer above exists also to reconcile styles that come from AkRenderer / Atlaskit
													 * and CSS loaded via Superbatch. While superbatch CSS is loaded later, order of precedence
													 * for Atlaskit styles and styles from this module is non-deterministic, so StyledRenderer
													 * styles can be overridden by Ak styles.
													 * To avoid this the wrapping div below is used to make StyledRenderer styles more specific.
													 */}
													{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
													<div className="renderer-overrides">
														<ReadingAidsInvisibleLoadingPortal />
														<WithAnnotationsWrapper
															isArchived={isArchived}
															annotationProvider={annotationProvider}
															rendererRef={localRef}
															adfDocument={adfDocument}
															updateDocument={updateDocument}
															isNestedRender={false}
															lastModifiedDate={lastModifiedDate}
														>
															<ADFRenderer
																// WARN: AkRenderer relies on remount to correctly initialize media providers, which
																// must be re-initialized whenever we show new content. Also under certain circumstances
																// simple modification of the content can mangle the content displayed by AK renderer.
																// Revision is used as content can change upon reload initiated by Very Quick Reload™ feature.
																// See https://product-fabric.atlassian.net/browse/ED-10791 for details.
																key={revision}
																appearance={contentAppearance}
																contentId={contentId}
																document={adfDocument}
																eventHandlers={eventHandlers}
																extensionHandlers={
																	isTemplateEditorRenderer ? extensionHandlers : handlers
																}
																portal={rootEl ?? undefined}
																renderWhenReady={renderPerformanceMetrics({
																	isFabric: true,
																})}
																includeNodesCountInStats
																onComplete={handleOnComplete}
																dataProviders={dataProviders}
																queryHash={queryHash}
																allowSelectAllTrap
																isContentHeaderFixedAtTop={isContentHeaderFixedAtTop}
																allowAnnotations={hasInlineComments}
																annotationProvider={annotationProvider}
																innerRef={localRef}
																UNSTABLE_textHighlighter={memoTextHighlighter}
																disableHeadingIDs={isSSR || hideHeadingIds}
																extensionViewportSizes={extensionViewportSizes}
																UNSTABLE_isPresentational={isPresentational}
																{...rest}
															/>
														</WithAnnotationsWrapper>
													</div>
												</MultiMacroQueryContainer>
											</AnalyticsListener>
										</ExtrensionIndexProvider>
									</StyledRenderer>
									{renderComplete && <MentionScrollComponent />}
									{displayInlineCommentNudger && state?.isContentReady && !isArchived && (
										<Fragment>
											<InlineCommentNudge
												key={revision}
												contentId={contentId}
												contentAppearance={contentAppearance}
											/>
											<InlineCommentLinkHandler />
										</Fragment>
									)}
									{isInReattachMode() && (
										<ReattachCommentPanelLoader
											containerId={contentId}
											updateDocument={updateDocument}
										/>
									)}
								</RendererTTIMarker>
							</MediaAnalytics>
						</div>
					);
				}}
			</Subscribe>
		</ErrorBoundary>
	);
};
export const PageContentRendererFabric: typeof PageContentRendererFabricInternal = (props) => {
	return (
		<UFOSegment name="page-content-renderer-fabric">
			<PageContentRendererFabricInternal {...props} />
		</UFOSegment>
	);
};
